export const sandTypes = [{
    label: 'Sabbia',
    value: 'sand'
}, {
    label: 'Ghiaia',
    value: 'gravel'
}, {
    label: 'Ciotoli',
    value: 'pebbles'
},{
    label: 'Roccia',
    value: 'rocks'
}]