import { format } from 'date-fns';
import {it} from "date-fns/locale";

export function formatDate(date, dateFormat) {

    const parsedDate = date && new Date(date)

    if (parsedDate instanceof Date && isFinite(parsedDate)) {
        return format(new Date(parsedDate), dateFormat, {locale: it})
    } else {
        return '-'
    }
}
