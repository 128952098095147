export function getAddressUrl(address) {
    let url = "https://maps.google.com/maps?saddr=&daddr=";
    if (!address) return url;

    if (address.street) {
        url = `${url}${address.street.replace(" ", "+")}`;
    }

    if (address.streetNumber) {
        url = `${url}%2C+${address.streetNumber}%2C`;
    }

    if (address.city) {
        url = `${url}+${address.city.replace(" ", "+")}`;
    }

    url = `${url}+Italia`;

    if (address.geocode?.coordinates) {
        url = `${url}@${address.geocode?.coordinates[1]},${address.geocode?.coordinates[0]}`;
    }

    return url;
}

export function getAddressString(address, options) {
    let addressString = "";
    if (address?.street && !options?.exsclude?.includes("street")) {
        addressString = `${address.street},`;
    }

    if (address?.streetNumber && !options?.exsclude?.includes("streetNumber")) {
        addressString = `${addressString} ${address.streetNumber},`;
    }

    if (address?.city && !options?.exsclude?.includes("city")) {
        addressString = `${addressString} ${address.city}`;
    }

    if (address?.district && !options?.exsclude?.includes("district")) {
        addressString = `${addressString} (${address.district})`;
    }

    return addressString
}