import { formatDate } from './dates';

export function getStatusString(status) {
    if (!status) return "";

    switch (status) {
        case "PENDING":
            return "Da rispondere";
        case "ACCEPTED":
            return "Preventivo inviato";
        case "DECLINED":
            return "Richiesta rifiutata";
        case "CONFIRMED":
            return "Confermato";
        case "REJECTED":
            return "Rifiutato dall'utente";
        case "PAID":
            return "Pagato dall'utente";
        default:
            return "";
    }
}

export function getTransactionsStatusString(status, date) {
    if (!status) return "";

    switch (status) {
        case "PENDING":
            return "In attesa di pagamento";
        case "ACCEPTED":
            return !date ? "Pagato" : `Pagato il ${formatDate(new Date(date), "dd MMM yyyy")}`;
        case "TRANSFERPENDING":
            return !date ? "Pagato" : `Pagato il ${formatDate(new Date(date), "dd MMM yyyy")}`;
        case "TRANSFERED":
            return !date ? "Pagato" : `Pagato il ${formatDate(new Date(date), "dd MMM yyyy")}`;
        case "REFOUNDED":
            return "Pagamento restituito";
        case "DECLINED":
            return "Pagamento rifiutato";
        case "CANCELED":
            return "Pagamento rifiutato";
        default:
            return "";
    }
}

export function getTransactionsTypeString(status) {
    if (!status) return "";

    switch (status) {
        case "TOTAL":
            return "Saldo";
        case "DOWN":
            return "Acconto";
        case "REFOUND":
            return "Rimborso";
        default:
            return "";
    }
}

export function getDurationTypeString(durationType) {
    if (!durationType) return "";

    switch (durationType) {
        case "daily":
            return "Giornaliero";
        case "afternoon":
            return "Pomeriggio";
        case "morning":
            return "Mattina";
        default:
            return "Giornaliero";
    }
}

export function getBeachesStatusString(status, options = {}) {
    if (!status) return "";

    const {isOnlineBeacharound, isPublicBeach, isFree} = options;

    if(!isPublicBeach && isFree && status !== "CANCELED") {
        return "Prenotazione gratuita"
    }

    switch (status) {
        case "PENDING":
            return "In attesa";
        case "ACCEPTED":
            if(isPublicBeach) {
                return "Accesso non eseguito"
            }
            return "In attesa di pagamento";
        case "DECLINED":
            return "Rifiutato";
        case "CONFIRMED":
            return "Confermato";
        case "REJECTED":
            return "Rifiutato dall'utente";
        case "PAID":
            if(isOnlineBeacharound) {
                return "Prenotato online - pagato"
            }
            return "Pagato";
        case "ACCESSED":
            if(isOnlineBeacharound) {
                return "Prenotato online - accesso eseguito"
            }
            return "Accesso eseguito";
        case "PARTIALPAID":
            if(isOnlineBeacharound) {
                return "Prenotato online - Saldo mancante"
            }
            return "Saldo mancante";
        case "OVERPAID":
            if(isOnlineBeacharound) {
                return "Prenotato online - Importo da restituire"
            }
            return "Importo da restituire";
        case "CANCELED":
            if(isOnlineBeacharound) {
                return "Prenotato online - Annullata"
            }
            return "Annullata";
        default:
            return "-";
    }
}

export function getBeachesStatusColor(status, options = {}) {
    if (!status) return "";

    const { isFree, isBeacharound } = options;

    if(isFree) {
        return "grey"
    }

    switch (status) {
        case "PENDING":
            return "red";
        case "ACCEPTED":
            return "red";
        case "DECLINED":
            return "red";
        case "CONFIRMED":
            return "red";
        case "REJECTED":
            return "red";
        case "PAID":
            if(isBeacharound) {
                return "main";
            }
            return "green";
        case "ACCESSED":
            return "green";
        case "OVERPAID":
            return "yellow";
        case "PARTIALPAID":
            return "yellow";
        default:
            return "";
    }
}


export function getStatusColor(status) {
    if (!status) return "";

    switch (status) {
        case "PENDING":
            return "red";
        case "ACCEPTED":
            return "green";
        case "DECLINED":
            return "grey1";
        case "CONFIRMED":
            return "blue";
        case "REJECTED":
            return "grey1";
        case "PAID":
            return "green";
        default:
            return "-";
    }
}

export function getStatusHistoryString(status) {
    if (!status) return "";

    switch (status) {
        case "CHECKINGOUT":
            return "L'utente iniziato a creare la prenotazione";
        case "PENDING":
            return "L'utente ha inviato la richiesta di prenotazione";
        case "ACCEPTED":
            return "La struttura ha accettato la prenotazione";
        case "DECLINED":
            return "La struttura ha rifiutato la prenotazione";
        case "CONFIRMED":
            return "L'utente ha confermato il nuovo prezzo di prenotazione";
        case "REJECTED":
            return "L'utente ha rifiutato il nuovo prezzo di prenotazione";
        case "PAID":
            return "L'utente ha pagato la prenotazione";
        default:
            return "";
    }
}

export function getPeopleString(details) {
    if (!details || !Array.isArray(details)) return "";
    return details
        .filter(({ key }) => ["adults", "children", "babies"].includes(key))
        .map(({ key, value }) => {
            let name;
            switch (key) {
                case "adults":
                    name = "Adulti";
                    break;
                case "children":
                    name = "Bambini";
                    break;
                case "babies":
                    name = "Neonati";
                    break;
                default:
                    name = "";
            }
            return `${value} ${name}`;
        })
        .join(", ");
}

export function getDateAndProductPlaced(date, item) {

    const formattedDate = date ? formatDate(new  Date(date), 'dd/MM/yy') : '-';

    if(item?.firstPlacedProduct?.customNumber) {
        return `${formattedDate} - <strong>${item?.firstPlacedProduct?.customNumber}</strong>`
    }

    if(item?.firstPlacedProduct?.index) {
        return `${formattedDate} - <strong>${item?.firstPlacedProduct?.index}</strong>`
    }
    return formattedDate;
}

export function getCustomersAndHotelsString(reservation, options) {
    let string = "";
    if(!reservation) {
        return string;
    }

    if(!reservation.customers && !reservation.hotels) {
        return "-"
    }

    const { customers, hotels } = reservation;

    if(customers && Array.isArray(customers)) {
        for (const customer of customers) {
            const { lastName, firstName } = customer;
            if(lastName || firstName) {
                if(options?.lastName) {
                    string = `${string.length > 0 ? `${string}, `: ""}${(lastName || firstName) || ""}`
                } else {
                    string = `${string.length > 0 ? `${string}, `: ""}${lastName || ""}${lastName && firstName ? " " : ""}${firstName || ""}`
                }
            }
        }
    }

    if(hotels && Array.isArray(hotels)) {
        for (const hotel of hotels) {
            const { name } = hotel;
            if(name) {
                string = `${string.length > 0 ? `${string}, da `: ""}${name}`
            }
        }
    }

    return string;
}