/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import classNames from 'classnames';

import { MapBuilderCell } from '../MapBuilderCell';
import { useMap } from '../MapContext';

import './style.scss';

export default function MapBuilderGrid({
    noIndex,
    center,
    sponsor,
    printStyle,
    noMarginTop
}) {

    const {
        options,
        columns,
        mapCellWidth,
        mapGridWidth,
        placedProducts,
        setProductOnBeach,
        createGridSize,
        selectedCells,
        isBuilder,
        onRowSelect,
        onColumnSelect,
        onMouseDown,
        infoMode,
    } = useMap();

    // Ci creiamo un array di sole passerelle per poter alleggerire il calcolo sucessivo
    const walkways = placedProducts?.filter(product => product.productType === "walkway");
    const threes = placedProducts?.filter(product => product.productType === "three");
    const water = placedProducts?.filter(product => product.productType === "water");

    // const wH = window.innerHeight;
    // const mapH = wH - (150);

    const GridClasses = classNames({
        "map-grid": true,
        "map-grid-print": printStyle,
        "map-builder-grid": isBuilder,
        "map-grid-center": center
    })

    // Selecione della riga
    function selectRow(index) {
        typeof onRowSelect === "function" && onRowSelect({
            row: index
        })
    }

    // Selezione della colonna
    function selectColumn(index) {
        typeof onColumnSelect === "function" && onColumnSelect({
            column: index
        })
    }

    const SponsorClasses = classNames({
        "sponsor-bar-item": true,
        "sponsor-bar-item-kite": sponsor?.type === 'kite',
        "sponsor-bar-item-plane": sponsor?.type === 'plane'
    })

    const GridSizerWrapperClasses = classNames({
        "map-grid-sizer-wrapper": true,
        "map-grid-sizer-wrapper-no-margin": noMarginTop,
    })

    const GridSizerClasses = classNames({
        "map-grid-sizer": true,
        "map-grid-sizer-custom-bg": options?.demoBackground || options?.background,
        "map-builder-grid-container": true,
        "map-grid-sizer-info-mode": infoMode,
    });

    return (
        <div className={GridSizerWrapperClasses}>

            <div className="map-actions-container">
            </div>

            <div ref={createGridSize} className={GridSizerClasses} onMouseDown={onMouseDown}>
                {mapCellWidth?.size && mapGridWidth ? (
                    <Fragment>
                        <div className="map-water" style={{ width: mapGridWidth }} >
                            {sponsor && (
                                <div
                                    className="sponsor-bar"
                                    style={(options?.background || options?.demoBackground) && {
                                        height: mapCellWidth?.size
                                    }}
                                >
                                    <div
                                        onClick={sponsor?.action}
                                        className={SponsorClasses}
                                    >
                                        <img src={sponsor?.image} />
                                    </div>
                                </div>
                            )}
                        </div>

                        <div
                            id="map-grid"
                            className={GridClasses}
                            style={{
                                width: mapGridWidth,
                                height: isBuilder ? mapCellWidth?.size * options.rows + 25 : mapCellWidth?.size * options.rows,
                            }}
                        >
                            {options?.demoBackground && (
                                <div className="map-grid-custom-bg-image">
                                    <img onDragStart={(e) => e.preventDefault()} src="/images/demo/beacharound-map-sfondo.jpg" />
                                </div>
                            )}

                            {options?.background && (
                                <div className="map-grid-custom-bg-image">
                                    <img onDragStart={(e) => e.preventDefault()} src={`${options.background}`} />
                                </div>
                            )}

                            {/* SELETTORE DELLA RIGA */}
                            {isBuilder && (
                                <div className="map-rows-controls">
                                    {columns?.[0].cells?.map((colCell, rowIndex) => {
                                        return (
                                            <div
                                                className="map-row-control"
                                                key={rowIndex}
                                                onClick={() => selectRow(rowIndex)}
                                            >
                                                <span>{rowIndex + 1}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            )}

                            {columns?.map(({ cells }, index) => {
                                return (
                                    <div key={`map-column-${index}`} className="map-column map-builder-column">
                                        {/* SELETTORE DELLA COLONNA */}
                                        {isBuilder && (
                                            <div className="map-column-control" onClick={() => selectColumn(index)}>
                                                <span>{index + 1}</span>
                                            </div>
                                        )}

                                        {cells?.map((cell) => {
                                            let product = placedProducts?.find((p) => p.column === cell.column && p.row === cell.row)
                                            // Se è una passerella andiamo a creare anche le connessioni
                                            let connections;

                                            if (product?.productType === "walkway") {
                                                const top = walkways.some(
                                                    (p) =>
                                                        p.column === product.column &&
                                                        p.row === product.row - 1
                                                );
                                                const bottom = walkways.some(
                                                    (p) =>
                                                        p.column === product.column &&
                                                        p.row === product.row + 1
                                                );
                                                const left = walkways.some(
                                                    (p) =>
                                                        p.row === product.row &&
                                                        p.column === product.column - 1
                                                );
                                                const right = walkways.some(
                                                    (p) =>
                                                        p.row === product.row &&
                                                        p.column === product.column + 1
                                                );
                                                connections = [
                                                    top,
                                                    right,
                                                    bottom,
                                                    left
                                                ]
                                            }

                                            if (product?.productType === "three") {
                                                const top = threes.some(
                                                    (p) =>
                                                        p.column === product.column &&
                                                        p.row === product.row - 1
                                                );
                                                const bottom = threes.some(
                                                    (p) =>
                                                        p.column === product.column &&
                                                        p.row === product.row + 1
                                                );
                                                const left = threes.some(
                                                    (p) =>
                                                        p.row === product.row &&
                                                        p.column === product.column - 1
                                                );
                                                const right = threes.some(
                                                    (p) =>
                                                        p.row === product.row &&
                                                        p.column === product.column + 1
                                                );
                                                connections = [
                                                    top,
                                                    right,
                                                    bottom,
                                                    left
                                                ]
                                            }

                                            if (product?.productType === "water") {
                                                const top = water.some(
                                                    (p) =>
                                                        p.column === product.column &&
                                                        p.row === product.row - 1
                                                );
                                                const bottom = water.some(
                                                    (p) =>
                                                        p.column === product.column &&
                                                        p.row === product.row + 1
                                                );
                                                const left = water.some(
                                                    (p) =>
                                                        p.row === product.row &&
                                                        p.column === product.column - 1
                                                );
                                                const right = water.some(
                                                    (p) =>
                                                        p.row === product.row &&
                                                        p.column === product.column + 1
                                                )

                                                connections = [
                                                    top,
                                                    right,
                                                    bottom,
                                                    left
                                                ]
                                            }

                                            let sectorBorder;

                                            if (product?.sector) {
                                                const topSector = placedProducts.some(
                                                    (p) =>
                                                        p.column === product.column &&
                                                        p.row === product.row - 1 &&
                                                        p.sector === product.sector
                                                );
                                                const bottomSector = placedProducts.some(
                                                    (p) =>
                                                        p.column === product.column &&
                                                        p.row === product.row + 1 &&
                                                        p.sector === product.sector
                                                );
                                                const leftSector = placedProducts.some(
                                                    (p) =>
                                                        p.row === product.row &&
                                                        p.column === product.column - 1 &&
                                                        p.sector === product.sector
                                                );
                                                const rightSector = placedProducts.some(
                                                    (p) =>
                                                        p.row === product.row &&
                                                        p.column === product.column + 1 &&
                                                        p.sector === product.sector
                                                );
                                                sectorBorder = [
                                                    !topSector,
                                                    !rightSector,
                                                    !bottomSector,
                                                    !leftSector
                                                ]
                                            }

                                            let isSelected;

                                            if (!Array.isArray(selectedCells) && selectedCells?.row !== undefined && selectedCells.column === undefined) {
                                                isSelected = selectedCells.row === cell.row;
                                            }

                                            if (!Array.isArray(selectedCells) && selectedCells?.column !== undefined && selectedCells.row === undefined) {
                                                isSelected = selectedCells.column === cell.column;
                                            }

                                            if (!Array.isArray(selectedCells) && selectedCells?.column !== undefined && selectedCells?.row !== undefined) {
                                                isSelected =
                                                    selectedCells.column === cell.column &&
                                                    selectedCells.row === cell.row;
                                            }

                                            if (Array.isArray(selectedCells)) {
                                                isSelected = selectedCells.some(({row: selectedRow, column: selectColumn}) => cell.column === selectColumn && cell.row === selectedRow)
                                            }

                                            if (product && noIndex && product.inactive) {
                                                product["index"] = undefined;
                                                product["customNumber"] = undefined;
                                            }

                                            return (
                                                <Fragment key={`${cell.column}-${cell.row}`}> 
                                                    <MapBuilderCell
                                                        {...cell}
                                                        product={product}
                                                        setProductOnBeach={setProductOnBeach}
                                                        size={mapCellWidth}
                                                        placed
                                                        connections={connections}
                                                        sectorBorder={sectorBorder}
                                                        isSelected={isSelected}
                                                    />
                                                </Fragment>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    </Fragment>
                ) : (
                    <Fragment></Fragment>
                )}
            </div>
        </div>
    )
}

