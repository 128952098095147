/* eslint-disable react/prop-types */
import React from 'react';
import { useMap } from '../MapContext';
import { format } from 'date-fns';
import classNames from 'classnames';
import { useDrag } from 'react-dnd';

import { Icon } from '@beacharound/beacharound-ui';
import { getBeachesStatusColor, formatDate, getBeachesStatusString } from "@beacharound/beacharound-ui-helpers";

export default function MapBuilderCellInfoReservationItem({
    product,
    reservation,
    virtual,
}) {
    const { onReservationMouseEnter, onReservationDrop } = useMap();

    let type = "reservations";
    if (product?.productType) {
        type = `reservations-${product?.productType}`;
    }

    const [, drag] = (!virtual && !!type) ? useDrag({
        type,
        item: {
            ...(reservation || {}),
            type
        },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if (!dropResult) {
                return;
            }
            return onReservationDrop({ reservation, newProduct: dropResult.product, oldProduct: product })
        }
    }) : [null, null];

    let title;
    let customerName = reservation?.customers?.[0]?.lastName;
    const isDaily = reservation?.isCheckin && reservation?.isCheckout;
    const isHalfDay = reservation?.durationType !== 'daily';
    const singleDay = reservation?.checkin === reservation?.checkout;
    const hasCheckin = reservation?.isCheckin && (reservation.checkin !== reservation.checkout);
    const hasCheckout = reservation?.isCheckout && (reservation.checkin !== reservation.checkout);

    const additionsList = reservation?.products?.filter(item => ['deckchair', 'cabin'].includes(item.type) && item._id !== product?.relatedProduct);
    const additionsShortText = additionsList?.map(add => {
        const quantity = add.quantity || 1;
        const name = add.name.substring(0, 2) || 'L';
        const connections = add?.connections?.join(',');
        return `${!connections ? quantity : ""}${name}${connections ? `(${connections})` : ''}`
    })?.join(',');
    const additionsText = additionsList?.map(add => {
        const quantity = add.quantity || 1;
        const name = add.name;
        return `${quantity} ${name}`
    })?.join(',');
    let cabinText;
    if (product?.productType === 'cabin') {
        cabinText = product?.relatedProductName;
    }

    let detailsText = reservation?.details?.map(detail => detail.text)?.join(', ') ?? "";

    if (reservation?.hasNotes) {
        if (detailsText?.length) {
            detailsText = "Prenotazione con note, " + detailsText;
        } else {
            detailsText = "Prenotazione con note";
        }
    }

    const Classes = classNames({
        'map-cell-info-reservation': true,
        'map-cell-info-reservation-half': isHalfDay,
        'map-cell-info-reservation-afternoon': reservation?.durationType === 'afternoon',
        'no-drag': !virtual,
    })

    const CellDatesClasses = classNames({
        'map-cell-text': true,
        'map-cell-text-dates': true,
        'text-highlight': reservation?.isSeasonal,
        'map-cell-text-dates-has-checkin': hasCheckin,
        'map-cell-text-dates-has-checkout': hasCheckout,
        'map-cell-text-halfday-dates-blink': isHalfDay && reservation?.checkin && reservation?.checkout && !isDaily,
        'no-drag': !virtual,
    });

    const CellDatesCheckinClasses = classNames({
        'map-cell-text-date': true,
        'map-cell-text-date-checkin': true,
        'no-drag': true
    });

    const CellDatesCheckoutClasses = classNames({
        'map-cell-text-date': true,
        'map-cell-text-date-checkout': true,
        'border-red': hasCheckout && ['ACCEPTED'].includes(reservation?.status),
        'text-red': hasCheckout && ['ACCEPTED'].includes(reservation?.status),
        'border-yellow': hasCheckout && ['PARTIALPAID'].includes(reservation?.status),
        'text-yellow': hasCheckout && ['PARTIALPAID'].includes(reservation?.status),
        'blink': hasCheckout && ['ACCEPTED'].includes(reservation?.status),
        'no-drag': true
    });

    if (hasCheckin) { title = `Checkin oggi` }
    if (hasCheckout) { title = `Checkout oggi` }
    if (singleDay) { title = `Giornaliera` }

    function onMouseEnterHandler() {
        if (onReservationMouseEnter && typeof onReservationMouseEnter === 'function') {
            return onReservationMouseEnter({ reservation, product })
        }
        return
    }

    return (
        <div
            ref={drag}
            className={Classes}
            onMouseEnter={onMouseEnterHandler}
            title={title}
        >

            <span className={`map-cell-info-bar bar-checkin ${getBeachesStatusColor(reservation.status, reservation)} no-drag`} title={getBeachesStatusString(reservation?.status)}></span>

            <div className="map-cell-text-container no-drag">
                {/* CLIENTE */}
                <p className="map-cell-customer map-cell-text bold no-drag" title={customerName}>{customerName || '-'}</p>

                {/* CHECKIN E CHECKOUT */}
                {!isHalfDay && reservation?.checkin && reservation?.checkout && !isDaily &&
                    <p className={CellDatesClasses} title={`Dal ${formatDate(new Date(reservation?.checkin), 'dd MMMM')} al ${formatDate(new Date(reservation?.checkout), 'dd MMMM')}`}>
                        <span className={CellDatesCheckinClasses}>{format(new Date(reservation?.checkin), 'dd/M')}</span>
                        -
                        <span className={CellDatesCheckoutClasses}>{format(new Date(reservation?.checkout), 'dd/M')}</span>
                    </p>
                }

                {isHalfDay && reservation?.checkin && reservation?.checkout && !isDaily && hasCheckout &&
                    <p className={CellDatesClasses} title={`Dal ${formatDate(new Date(reservation?.checkin), 'dd MMMM')} al ${formatDate(new Date(reservation?.checkout), 'dd MMMM')}`}>
                        <span className={CellDatesCheckoutClasses}>{format(new Date(reservation?.checkout), 'dd/M')}</span>
                    </p>
                }

                {/* GIORNALIERO */}
                {!isHalfDay && isDaily &&
                    <p className="map-cell-text map-cell-text-today small no-drag">GIORNALIERO</p>
                }

                {/* PRODOTTI */}
                {additionsShortText && !cabinText &&
                    <p className="map-cell-text small no-drag" title={additionsText}>{additionsShortText}</p>
                }

                {/* TESTO SE CABINA */}
                {cabinText &&
                    <p className="map-cell-text small no-drag" title={cabinText}>{cabinText}</p>
                }

                {!isHalfDay &&
                    <div className='map-cell-info-details no-drag' title={detailsText}>
                        {reservation?.hasNotes &&
                            <div key={reservation?._id + "-notes"} className='map-cell-info-detail no-drag'>
                                <Icon icon="edit" />
                            </div>
                        }
                        {reservation?.details?.map(({ icon, fa, faStyle }, index) => {
                            return (
                                <div key={index} className='map-cell-info-detail no-drag'>
                                    <Icon
                                        icon={icon}
                                        fa={fa}
                                        faStyle={faStyle}
                                    />
                                </div>
                            )
                        })}
                    </div>
                }
            </div>

            {/* {reservation?.isScheduleBefore &&
                <span className={`map-cell-info-sched sched-before ${getBeachesStatusColor(reservation.status)}`}></span>
            } */}
            {/* {reservation?.isCheckin &&
                <span className={`map-cell-info-bar bar-checkin ${getBeachesStatusColor(reservation.status)}`}></span>
            } */}
            {/* {reservation?.isScheduleAfter &&
                <span className={`map-cell-info-sched sched-after ${getBeachesStatusColor(reservation.status)}`}></span>
            } */}
            {/* {reservation?.isCheckout &&
                <span className={`map-cell-info-bar bar-checkout ${getBeachesStatusColor(reservation.status)}`}></span>
            } */}
        </div>
    )
}