/* eslint-disable react/prop-types */
import React from 'react';
import { useDrag } from 'react-dnd';
import { Icon } from '@beacharound/beacharound-ui';

import MapChip from '../MapChip';

import { useMap } from '../MapContext';

export default function MapBuilderProduct({ product, placed, connections, size }) {

    const { isBuilder, imagesPathRoot, setProductOnBeach, infoMode, showFuture } = useMap();

    let imagePath = product.category;

    if(product?.color) {
        imagePath += `-${product.color}`
    }

    if (["walkway", "three", "water"].includes(product?.productType) && placed && connections) {
        imagePath += "-";
        connections.map((hasConnection) =>
            hasConnection ? (imagePath += "1") : (imagePath += 0)
        )
    }

    const prodImage = `${imagesPathRoot}${imagePath}.png`;

    const hasProductCustomIndex = product.customNumber && product.customNumber !== "undefined";
    const hasProductIndex = placed && (product.index !== undefined || hasProductCustomIndex) && !["walkway", "three"].includes(product?.productType);
    let productIndex = hasProductCustomIndex ? product.customNumber : product.index;

    let elementProductText = product.name;

    if(product.prefix) {
        elementProductText = `${product.prefix} - ${elementProductText}`;
    }

    const item = {
        ...product,
        type: product.gridType,
        _id: undefined,
        origin: product._id,
        index: !hasProductCustomIndex && productIndex,
    };

    const [, drag] = isBuilder ? useDrag({
        type: product.gridType,
        item,
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if(!dropResult) {
                return;
            }
            const { column, row, dropEffect } = dropResult;
            return setProductOnBeach({ column, row, product: item }, dropEffect)
        }
    }) : [];

    let reservedList = [];

    if(product.isHotelReserved) {
        reservedList = [...reservedList, {icon: "hotel", text: "Riservato hotel"}]
    }

    if(product.isManagementReserved) {
        reservedList = [...reservedList, {icon: "not-visible", text: "Disattivato online sempre", color: 'red'}]
    }

    if(product.isDeactivatedToday && !product.isManagementReserved) {
        reservedList = [...reservedList, {icon: "cirle-minus", text: "Disattivato online oggi", color: 'red'}]
    }

    if(product.isDeactivatedFuture && !product.isManagementReserved) {
        reservedList = [...reservedList, {icon: "alert", text: "Disattivato online in futuro", color: 'red'}]
    }

    if(product.fromDate && product.toDate) {
        reservedList = [...reservedList, {icon: "calendar-check", faStyle: 'regular', fa: true, text: "Prodotto momentaneo", color: 'grey'}]
    }

    if(product.icons?.length) {
        reservedList = [...reservedList, ...product.icons]
    }

    let transformContainer = "";
    let transformProduct = "";

    if(product.transform?.rotate && !["three", "water"].includes(product?.productType)) {
        transformProduct = `${transformProduct} rotate(${product.transform?.rotate}deg)`

        if(["walkway"].includes(product?.productType) && [45, 135, 225, 315].includes(product.transform?.rotate)) {
            transformProduct = `${transformProduct} scaleY(1.5)`
        }
    }

    if(product.transform?.translateX) {
        transformContainer = `${transformContainer} translateX(${product.transform?.translateX}%)`
    }

    if(product.transform?.translateY) {
        transformContainer = `${transformContainer} translateY(${product.transform?.translateY}%)`
    }

    return (
        <div
            ref={isBuilder && drag}
            className="map-product map-builder-product"
            style={{
                transform: transformContainer
            }}
        >
            <div className="map-product-image-container">
                <div
                    className="map-product-image map-builder-product-image"
                    style={{
                        transform: transformProduct,
                        backgroundImage: `url('${prodImage}')`,
                        top: 0,
                        left: 0
                    }}
                ></div>
            </div>

            {/* CHIP DEI PRODOTTI INSERITI */}
            {hasProductIndex && (
                <MapChip key={productIndex} text={productIndex} isBuilder={isBuilder} size={size} showFuture={showFuture} {...product} />
            )}

            {reservedList.length > 0 && (!product?.reservations?.length || !!infoMode) && 
                <div className="map-product-reserved-list">
                    {reservedList.map(({icon, fa, faStyle, text, color}, index) => (
                        <div className={`map-product-reserved-icon-container ${color || ''}`} key={index}>
                            <Icon
                                icon={icon}
                                fa={fa}
                                faStyle={faStyle}
                            />
                            <span className="map-product-reserved-tooltip-container">
                                <p className="map-product-reserved-tooltip-text">{text}</p>
                            </span>
                        </div>
                    ))}
                </div>
            }

            {/* NOME DEI PRODOTTI NON INSERITI */}
            {!placed && <div className="map-product-text">{elementProductText?.substring(0, 20)}</div>}
        </div>
    );
}