/* eslint-disable react/prop-types */
import React, {
    useState,
    useEffect
} from 'react';

import { MapContext, createColumns } from '../MapContext';
import { sortProducts } from '@beacharound/beacharound-ui-helpers';
import { mapSizes } from '../../lib/mapHelpers';

function debounce(fn, ms) {
    let timer;

    return () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            fn.apply(this, arguments)
        }, ms)
    }
}

export default function MapConfigurator({
    children,
    options,
    isBuilder,
    products: originProducts,
    ignoreSort,
    placedProducts,
    onCellClick,
    selectedCells,
    imagesPathRoot,
    onProductPlaced,
    onCellSelect,
    onMouseEnterCell,
    onRowSelect,
    onColumnSelect,
    selectOnClick,
    closeSidebars,
    defaultSize,
    scrollToFirst,
    mapSize,
    onChangeMapSize: changeMapSize,
    onReservationMouseEnter,
    onReservationDrop,
    canDrop
}) {

    let baseSize = mapSizes?.find(el => el.name === 'L');
    if(defaultSize) {
        const externalSize = mapSizes?.find(el => el.name === defaultSize);
        if(externalSize) {
            baseSize = externalSize;
        }
    }

    const [dragging, setDragging] = useState(false);
    const [closeFilter, setCloseFilter] = useState();
    const [gridSizerElement, setGridSizerElement] = useState();
    const [firstScrolled, setFirstScrolled] = useState();
    const [mapCellWidth, setBeachCellWidth] = useState(baseSize);
    const [mapGridWidth, setBeachGridWidth] = useState();
    // eslint-disable-next-line no-unused-vars
    const [products, setProducts] = useState(originProducts || []);
    const [infoMode, setInfoMode ] = useState(JSON.parse(localStorage.getItem('info') || "true"));
    const [showFuture, setShowFuture ] = useState(JSON.parse(localStorage.getItem('map-future') || "true"));
    const [selectedSector, setSelectedSector] = useState();
    // const [isMulti, setIsMulti ] = useState(false);

    const rowControlSize = options?.customBg ? 0 : 25;

    function createGridSize(element) {
        if (element) {
            const { columns } = options;
            // const { rows, minSize } = options;
            // const maxCount = Math.max(columns, rows);
            // const minDimension = Math.min(element.clientWidth, element.clientHeight)
            // let newCellWidth = (minDimension - 50) / (maxCount + 0.5);
            // let cellLimit = minSize || 80;

            // if (newCellWidth < cellLimit) {
            //     newCellWidth = cellLimit;
            // }

            // setBeachCellWidth(newCellWidth);

            let gridWidth = mapCellWidth?.size * columns + rowControlSize;

            if(element.clientWidth > gridWidth) {
                gridWidth = element.clientWidth;
            }
            // if(window.innerWidth > gridWidth) {
            //     gridWidth = window.innerWidth;
            // }
            setBeachGridWidth(gridWidth);
            return setGridSizerElement(element)
        }
    }

    function changeCellSize(size) {
        const newSize = mapSizes?.find(el => el.name === size?.name)

        if (!newSize) { return }

        if(changeMapSize && typeof changeMapSize === "function") {
            changeMapSize(newSize?.name);
        }
        return setBeachCellWidth(newSize)
    }

    // Lo rendiamo reattivo al resize del window

    useEffect(() => {
        const debouncedHandleResize = debounce(function resizing() {
            createGridSize(gridSizerElement);
        }, 500)

        window.addEventListener('resize', debouncedHandleResize);

        // document.addEventListener('keydown', (event) => {
        //     if(event.keyCode === 16) {
        //         return setIsMulti(true);
        //     }
        // });

        // document.addEventListener('keyup', (event) => {
        //     if(event.keyCode === 16) {
        //         return setIsMulti(false);
        //     }
        // })

        return () => window.removeEventListener('resize', debouncedHandleResize)
    }, []);

    useEffect(() => {
        if(selectedSector) {
            return scrollToCell({ row: selectedSector.row, column: selectedSector.column })
        }
        if(scrollToFirst && gridSizerElement && !firstScrolled) {
            if(scrollToFirst?.row !== undefined && scrollToFirst?.column !== undefined) {
                return scrollToCell(scrollToFirst);
            }
            const firstEmpty = placedProducts.find(({inactive}) => !inactive);
            if(firstEmpty) {
                return scrollToCell(firstEmpty)
            }
            const firstCell = placedProducts[0];
            if(firstCell) {
                return scrollToCell(firstCell)
            }
            return;
        }
    }, [scrollToFirst, gridSizerElement, selectedSector]);

    useEffect(() => {
        if(mapSize) {
            setBeachCellWidth(mapSizes?.find(({ name }) => name === mapSize));
        }
    }, [mapSize, setBeachCellWidth]);

    function translateGrid(direction) {

        if(!gridSizerElement) {
            return console.log("Non è stato trovato alcun elemento da translare")
        }

        let newX = Math.floor(gridSizerElement.scrollLeft / mapCellWidth?.size);
        let newY = Math.floor(gridSizerElement.scrollTop / mapCellWidth?.size);

        let scrollOptions = {
            behavior: 'smooth'
        }

        switch (direction) {
            case "top":
                newY--
                break;
            case "bottom":
                newY++
                break;
            case "left":
                newX--
                break;
            case "right":
                newX++
                break;
            default:
                break;
        }

        if (gridSizerElement.scrollLeft !== mapCellWidth * newX) scrollOptions["left"] = newX * mapCellWidth;
        if (gridSizerElement.scrollTop !== mapCellWidth * newY) scrollOptions["top"] = newY * mapCellWidth;

        return gridSizerElement.scroll(scrollOptions)
    }

    function scrollToCell({row, column}) {
        if(!gridSizerElement) {
            return;
        }
        const { size } = mapCellWidth || {};
        const x = column * size;
        const y = row * size;
        setFirstScrolled(true);
        const offset = size * 4;
        return gridSizerElement.scroll({
            behavior: 'smooth',
            top: y - offset,
            left: x - offset
        })
    }

    function setProductOnBeach({ column, row, product }, type) {

        let newProductsArray = placedProducts || [];
        const existingProductInPlace = placedProducts?.find(p => p.column === column && p.row === row);

        if (existingProductInPlace && existingProductInPlace.name === product.name) {
            return console.log(`prodotto già piazzato nella cella ${column} - ${row}`)
        }

        if (existingProductInPlace && existingProductInPlace.name !== product.name) {
            const existingProductInPlaceIndex = placedProducts.findIndex(p => p.column === column && p.row === row);
            newProductsArray.splice(existingProductInPlaceIndex, 1)
        }

        // shif premuto
        if (type === "copy") {
            newProductsArray = sortProducts([...newProductsArray, { ...product, column, row }], options)
        } else {
            const existingProductInPlaceIndex = placedProducts.findIndex(p => p._id === product.origin);
            if(existingProductInPlaceIndex < 0 || !product.origin) {
                newProductsArray = [
                    ...newProductsArray,
                    {
                        ...product,
                        column,
                        row
                    }
                ]
            } else {
                newProductsArray[existingProductInPlaceIndex] = {
                    ...newProductsArray[existingProductInPlaceIndex],
                    column,
                    row
                };
            }
            newProductsArray = sortProducts(newProductsArray, options)
        }

        // newProductsArray = sortProducts([...newProductsArray, { ...product, column, row }], options);

        return onProductPlaced({ ...product, column, row }, newProductsArray)
    }

    function setInfoModeHandler(value) {
        localStorage.setItem('info', value);
        return setInfoMode(value);
    }

    function setShowFutureHandler(value) {
        localStorage.setItem('map-future', value);
        return setShowFuture(value);
    }

    // Trascinamento della griglia
    let position = {
        gridTop: 0,
        gridLeft: 0,
        mouseX: 0,
        mouseY: 0
    };

    function mouseDownHandler(event) {
        const elementToIgnore = event.target.classList.contains('no-drag');

        if(elementToIgnore) {
            return;
        }

        if(!isBuilder && gridSizerElement && ![1, 2].includes(event?.button)) {
            position = {
              gridLeft: gridSizerElement.scrollLeft,
              gridTop: gridSizerElement.scrollTop,
              mouseX: event.clientX,
              mouseY: event.clientY,
            };

            document.addEventListener('mousemove', mouseMoveHandler);
            document.addEventListener('mouseup', mouseUpHandler);
        }
    }

    function mouseMoveHandler(event) {

        const deltaX = event.clientX - position.mouseX;
        const deltaY = event.clientY - position.mouseY;

        if (deltaX !== 0 || deltaY !== 0) {
            setDragging(true);
            gridSizerElement.style.cursor = 'grabbing';
            gridSizerElement.scrollTop = position.gridTop - deltaY;
            gridSizerElement.scrollLeft = position.gridLeft - deltaX;
        }
    }

    function mouseUpHandler() {
        setDragging(false);
        gridSizerElement.style.cursor = 'grab';

        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
    }

    function selectSectorHandler(sector) {
        return setSelectedSector(sector);
    }

    return (
        <MapContext.Provider
            value={{
                options,
                products,
                placedProducts: !ignoreSort ? sortProducts(placedProducts, options) : placedProducts,
                columns: createColumns(options),
                mapCellWidth,
                mapGridWidth,
                imagesPathRoot,
                cellSize: mapCellWidth,
                changeCellSize,
                createGridSize,
                translateGrid,
                setProductOnBeach,
                onCellClick,
                onCellSelect,
                onMouseEnterCell,
                onMouseDown: mouseDownHandler,
                onReservationMouseEnter,
                onReservationDrop,
                canDrop,
                onColumnSelect,
                onRowSelect,
                isBuilder,
                selectedCells,
                mapGutter: rowControlSize,
                infoMode,
                setInfoModeHandler,
                showFuture,
                setShowFutureHandler,
                selectOnClick,
                closeSidebars,
                scrollToCell,
                dragging,
                closeFilter,
                setCloseFilter,
                selectedSector,
                selectSector: selectSectorHandler
            }}
        >
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child);
                }

                return child;
            })}
        </MapContext.Provider>
    );
}
