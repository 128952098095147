const versions = [
    {
        size: "xs",
        name: "160x120",
    },
    {
        size: "s",
        name: "280x210",
    },
    {
        size: "m",
        name: "560x420",
    },
    {
        size: "l",
        name: "800x600",
    },
    {
        size: "xl",
        name: "1600x1200",
    }
]

// export function getImageCrop(cover) {
export function getImageCrop(cover, size) {

    // return cover

    if(!cover || typeof(cover) !== "string") return cover;
    
    const foundSize = versions.find(version => version.size === size);

    if(!foundSize) return cover;

    const newSize = foundSize.name;
    return cover.replace("/originals/", `/${newSize}/`)

}


