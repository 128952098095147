/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { isMobile } from "@beacharound/beacharound-ui-helpers";

import './style.scss';

export default function MapContainer({
    children,
    fullWidth,
    fullScreen,
    marginBottom,
    padding,
    low,
    dnd
}) {

    const MapClasses = classNames({
        "map-container": true,
        "map-container-padding": padding,
        "map-container-full-width": fullWidth,
        "map-container-full-screen": fullScreen,
        "map-container-margin-bottom": marginBottom,
        "map-container-low": low,
    })

    // if (window.__isReactDndBackendSetUp) {
    //     window.__isReactDndBackendSetUp = false
    // }

    return (
        <div className={MapClasses}>
            <DndProvider
                backend={isMobile() ? TouchBackend : HTML5Backend}
                options={{ delayTouchStart: 150 }}
                context={window}
                key={1}
            >
                { React.Children.map(children, (child) => {

                    if (React.isValidElement(child)) {
                        return React.cloneElement(child)
                    }

                    return child;
                })}
            </DndProvider>
        </div>
    )
}
