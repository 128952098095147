const bedNames = [{
    type: "single",
    name: "Letto singolo"
}, {
    type: "queen",
    name: "Letto una piazza e mezza"
}, {
    type: "double",
    name: "Letto matrimoniale"
}, {
    type: "king",
    name: "Letto king"
}];

export function getBedName(type) {
    if(!type) return "";
    
    const bed = bedNames.find(bedName => bedName.type === type);

    return bed ? bed.name : "";
}

export const bedsOptions = bedNames.map(({name, type}) => {
    return {
        label: name,
        value: type
    }
})