const alphabet = "abcdefghijklmnopqrstuvwxyz".toUpperCase().split("");

export function getProductPrefix(product, {filtering} = {}) {

    if(!product) {
        return "";
    }

    console.log(product);

    let productIndex = product?.index?.toString();

    if(productIndex?.includes(`${product.relatedProductPrefix}-`)) {
        return ""
    }
    
    if(filtering === "flat" || !filtering) {
        return "";
    }

    if(filtering === "categories") {
        let category = product.category;
        const categoryString = category?.toUpperCase().substring(0, 3);
        if(productIndex?.includes(`${categoryString}-`)) {
            return ""
        }
        return product.relatedProductPrefix?.toUpperCase().substring(0, 3) || categoryString;
    }

    if(filtering === "products") {
        const productPrefix = product.relatedProductName?.toUpperCase().substring(0, 3);
        if(productIndex?.includes(`${productPrefix}-`)) {
            return ""
        }
        return product.relatedProductPrefix?.toUpperCase().substring(0, 3) || productPrefix
    }

    return "";
}

export function getProductIndex(product, {filtering} = {}) {

    if(!product) {
        return "";
    }

    const {index, customNumber} = product;

    if(customNumber) {
        return customNumber;
    }

    const prefix = getProductPrefix(product, {filtering});
    return `${prefix}${prefix?.length > 0 ? "-" : ""}${index}`
}

export function getProductsIndex(products, {filtering} = {}) {
    if(!products) {
        return [];
    }

    return products.map(product => ({
        ...product,
        index: getProductIndex(product, {filtering})
    }))
}

export function sortProducts(products, {sort, filtering, numbering, alphanumericDirection} = {}) {

    if(!products) {
        return [];
    }

    // Prima prendo solo i prodotti che non hanno un customNumber
    let productsToSort = products.filter(product => (product.customNumber === undefined || product.customNumber === "undefined" || product.customNumber === null) && product.gridType !== "furniture");
    const productsToLeaveAsTheyAre = products.filter(product => (!!product.customNumber && product.customNumber !== "undefined") || product.gridType === "furniture");

    // Controllo filtering e in base a quello vado a ordinare i prodotti

    let productsToSortArray = [];

    if(filtering === "flat" || !filtering) {
        productsToSortArray = [productsToSort.map(product => {
            return {
                ...product,
                prefix: product.relatedProductPrefix?.toUpperCase().substring(0, 3)
            }
        })]
    }

    if(filtering === "categories") {
        
        let categories = productsToSort.reduce((acc, product) => {
            return  acc.some(category => category === product.category) ? acc : [...acc, product.category]
        }, []);

        productsToSortArray = categories
            .map((category) =>
                productsToSort.filter(
                    (product) => product.category === category
                ).map((product) => {
                    return {
                        ...product,
                        prefix:
                            product.relatedProductPrefix
                                ?.toUpperCase()
                                .substring(0, 3) ||
                            category.toUpperCase().substring(0, 3),
                    };
                })
            )
    }

    if(filtering === "products") {
        let productsReferences = productsToSort.reduce((acc, product) => {
            return acc.some(
                (relatedProduct) => relatedProduct === product.relatedProduct
            )
                ? acc
                : [...acc, product.relatedProduct];
        }, []);

        productsToSortArray = productsReferences.map((relatedProduct) =>
            productsToSort.filter(
                (product) => product.relatedProduct === relatedProduct
            ).map(product => {
                return {
                    ...product,
                    prefix: product.relatedProductPrefix?.toUpperCase().substring(0, 3) || product.relatedProductName?.toUpperCase().substring(0, 3)
                }
            })
        );
    }

    // Numeriamo gli elementi secondo le opzioni di numerazione

    let numberedProductsArray = productsToSortArray;

    if(numbering === "alphanumeric") {

        let orderedAlphabeth = alphabet;
        if(sort.vertical === "bottom" && alphanumericDirection === "horizontal") {
            orderedAlphabeth = alphabet.reverse();
        }
        if(sort.horizontal === "right" && alphanumericDirection === "vertical") {
            orderedAlphabeth = alphabet.reverse();
        }
        
        numberedProductsArray = productsToSortArray.reduce((arrayAccumulator, productArray) => {
            // Devo fare tanti array quanti solo le righe o le colonne;

            const alphaProducts = productArray.reduce((acc, product) => {
                const productElementor = alphanumericDirection === "vertical" ? "column" : "row";
                const findProductArrayIndex = acc.findIndex(arr => arr.some(el => el[productElementor] === product[productElementor]));
                if(findProductArrayIndex > -1) {
                    acc[findProductArrayIndex] = [
                        ...acc[findProductArrayIndex],
                        product
                    ]
                    return acc;
                }
                return [...acc, [product]];
            }, []).map(array => {
                return array.sort((a, b) => {
                    const hFirst = sort?.horizontal === "left" ? a : b;
                    const hSecond = sort?.horizontal === "left" ? b : a;
        
                    const vFirst = sort?.vertical === "top" ? a : b;
                    const vSecond = sort?.vertical === "top" ? b : a;
        
                    return vFirst.row - vSecond.row || hFirst.column - hSecond.column;
                }).map((product, index) => {
                    const productElementor = index;
                    const alphaLength = Math.floor(productElementor / alphabet.length);
                    const remainder = productElementor % alphabet.length;
            
                    let alphaString = "";
                    for (let index = 0; index <= alphaLength; index++) {
    
                        const alphaLoopString =
                            index < alphaLength
                                ? orderedAlphabeth[index]
                                : orderedAlphabeth[index + remainder]; 
                        alphaString = `${alphaString}${alphaLoopString}`            
                    }
                    
                    return {
                        ...product,
                        alpha: alphaString
                    }
    
                })
            }).reduce((acc, array) => array ? [...acc, ...array] : acc, [])

            const alphaArrays = alphaProducts.reduce((acc, product) => {
                return acc.some((alpha) => alpha === product.alpha)
                    ? acc
                    : [...acc, product.alpha];
            }, []);

            const alphaProductsArrays = alphaArrays.map((alpha) =>
                alphaProducts.filter(
                    (product) => product.alpha === alpha
                )
            );

            return [...arrayAccumulator, ...alphaProductsArrays]

        }, []);
    }

    // Faccio il merge degli array numerandoli secondo le opzioni;

    let sortedProducts = numberedProductsArray.reduce((acc, arrayToSort) => {
        
        let orderedArray = arrayToSort.sort((a, b) => {
            const hFirst = sort?.horizontal === "left" ? a : b;
            const hSecond = sort?.horizontal === "left" ? b : a;

            const vFirst = sort?.vertical === "top" ? a : b;
            const vSecond = sort?.vertical === "top" ? b : a;

            return vFirst.row - vSecond.row || hFirst.column - hSecond.column;
        }).map((product, index) => {
            const realIndex = index + 1;
            let indexString = realIndex;

            if(product.alpha && numbering === "alphanumeric") {
                indexString = `${product.alpha}${indexString}`;
            }
            if(product.prefix) {
                indexString = `${product.prefix}-${indexString}`;
            }
            
            return {
                ...product,
                index: indexString,
            };
        });

        return [...acc, ...orderedArray];
    }, []);

    return [...sortedProducts, ...productsToLeaveAsTheyAre];
}