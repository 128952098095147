import {
    trackEvent as trackGAEvent,
    setTrackConfig as setTrackGAConfig,
    createProduct as createGAProduct,
} from "./ga.js";

import {
    trackEvent as trackFBEvent,
    setTrackConfig as setTrackFBConfig,
    createProduct as createFBProduct,
} from "./pixel.js";

export function trackPage({ path, name } = {}) {
    if (!name) {
        console.warn("Analytics", "Name mancante");
        return;
    }

    let realName = name;

    if(Array.isArray(name)) {
        realName = name.reduce((acc, str) => `${acc}${acc.length > 0 ? " | " : ""}${str}`, "");
    }

    setTrackGAConfig({
        page: path || window.location.pathname,
        page_title: realName,
    });

    trackFBEvent({
        action: "PageView"
    });

    return trackGAEvent({
        action: "page_view",
    });
}
export function trackLogin({ method, user } = {}) {
    if (user?._id) {
        setUserIdTrack({ userId: user._id });
    }
    return trackGAEvent({
        action: "login",
        props: {
            method: method || "email",
        },
    });
}

export function trackSignup({ method, user } = {}) {
    if (user?._id) {
        setUserIdTrack({ userId: user._id });
    }
    trackFBEvent({
        action: "CompleteRegistration",
        props: {
            content_name: "Signup",
            currency: "EUR",
            status: true,
            value: 0,
        },
    });
    return trackGAEvent({
        action: "sign_up",
        props: {
            method: method || "email",
        },
    });
}

export function trackSearch({ search } = {}) {
    if (!search) {
        console.warn("Analytics", "search mancante");
        return;
    }
    trackFBEvent({
        action: "Search",
        props: {
            search_string: search
        },
    });
    return trackGAEvent({
        action: "search",
        props: {
            search_term: search,
        },
    });
}

export function trackAddToCart(product) {
    if ((!product || !product.name || !product._id || !product.type)) {
        console.warn("Analytics", "valori prodotto mancanti");
        return;
    }
    trackFBEvent({
        action: "AddToCart",
        props: createFBProduct(product),
    });
    return trackGAEvent({
        action: "add_to_cart",
        props: {
            currency: "EUR",
            items: [createGAProduct(product)],
            value: product?.value || 0,
        },
    });
}

export function trackPurchase(product) {
    if ((!product || !product.name || !product._id || !product.type)) {
        console.warn("Analytics", "valori prodotto mancanti");
        return;
    }
    trackFBEvent({
        action: "Purchase",
        props: createFBProduct(product),
    });
    return trackGAEvent({
        action: "purchase",
        props: {
            currency: "EUR",
            items: [createGAProduct(product)],
            value: product?.value || 0,
            transaction_id: product?.transaction,
        },
    });
}

export function setTrackUserData({user} = {}) {
    if(!user) {
        return console.warn("Analytics", "Nessun dato trovato per use")
    }

    return setTrackFBConfig({
        em: user.email,
        fn: user.firstName,
        ln: user.lastName,
        ph: user.phone,
        external_id: user._id,
        db: user.birthday
    });
}

export function setUserIdTrack({ userId } = {}) {
    return (
        userId &&
        setTrackGAConfig({
            user_id: userId,
        })
    );
}

export function trackLead({type, activityType} = {}) {
    trackFBEvent({
        action: "Lead",
        props: {
            [activityType && "content_category"]: activityType,
            content_name: type,
        },
    });
    return trackGAEvent({
        action: "generate_lead",
        props: {
            currency: "EUR",
            value: 0,
        },
    });
}

export function trackCall(props) {
    trackFBEvent({
        action: "Contact",
        props
    });
    return trackGAEvent({
        action: "call",
        props
    });
}
