let csvContent = "data:text/csv;charset=utf-8,";
const searchVirgole = new RegExp(',', 'g');
const searchAsteristchi = new RegExp('#', 'g');

export function createCsvUrl(elements) {
    let headArray = [];
    let elementsInArrays = [];
    elements.forEach(element => {
        const keys = Object.keys(element);
        keys.forEach(key => !headArray.includes(key) && headArray.push(key));
    })
    elements.forEach(element => {
        let elementArray = [];
        headArray.forEach(key => {
            let string = element[key] || " ";
            if(typeof string === "string") {
                string = string.replace(searchVirgole, " |").replace(searchAsteristchi, "")
            }
            elementArray = [
                ...elementArray,
                string
            ]
        });
        elementsInArrays = [...elementsInArrays, elementArray]
    });
    elementsInArrays = [headArray, ...elementsInArrays];
    const thisCsvContent = `${csvContent}${elementsInArrays.map(e => e.join(",")).join("\n")}`;
    const encodedUri = encodeURI(thisCsvContent);
    return encodedUri;
}