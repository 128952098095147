/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import classNames from 'classnames';
import { useDrop } from 'react-dnd';

import { useMap } from '../MapContext';
import { useDropdown } from '@beacharound/beacharound-ui';
import { getCustomersAndHotelsString } from "@beacharound/beacharound-ui-helpers";
import MapBuilderProduct from '../MapBuilderProduct';
import { MapBuilderCellInfo } from '../MapBuilderCellInfo';
import { MapBuilderCellInfoSlots } from '../MapBuilderCellInfoSlots';

export const MapBuilderCell = ({
    column,
    row,
    product,
    size,
    connections,
    sectorBorder,
    isSelected
}) => {

    let hasClick = true;
    const { isBuilder, closeSidebars, selectOnClick, onCellSelect, onMouseEnterCell, dragging, closeFilter, selectedSector, canDrop: canDropOption, showFuture } = useMap();
    const { setIsOpen: openDropdown } = (selectOnClick || typeof useDropdown !== "function" || !useDropdown()) ? {} : useDropdown();

    const accept = isBuilder ? ['product', 'furniture'] : [`reservations-${product?.productType}`];
    const canDrop = isBuilder ? !product : (
        !["furniture"].includes(product?.gridType) &&
        canDropOption
    );
    const dropResult = isBuilder ? { column, row } : { product };

    const [{ isOver }, drop] = useDrop({
        accept,
        drop: () => dropResult,
        canDrop: () => canDrop,
        collect: (monitor) => ({ isOver: monitor.isOver() })
    });

    const cellClasses = classNames({
        "map-cell": true,
        "map-builder-cell": isBuilder,
        [`map-builder-cell-${size?.name}`]: size?.name,
        "map-builder-cell-over": isOver,
        "map-cell-selected": isSelected,
        "map-cell-inactive": product?.inactive,
        "map-cell-low-light": product?.lowLight,
        "map-cell-furniture": product?.gridType === "furniture",
        "map-cell-product": product?.gridType === "product",
        "map-cell-print-hide-sector": !!selectedSector && product?.sector !== selectedSector?._id,
        "map-cell-empty": !product
    });

    if (product?.inactive && !isBuilder) {
        hasClick = false;
    }
    if (product?.gridType === "furniture" && !isBuilder) {
        hasClick = false;
    }

    function cellClickHandler(product, event) {

        if (product?.lowLight && closeFilter && typeof closeFilter === "function") {
            return closeFilter();
        }

        if (selectOnClick) {
            return onCellSelect(product)
        }

        openDropdown()

        if (closeSidebars && typeof closeSidebars === 'function') {
            closeSidebars()
        }

        if ((product?.gridType !== 'furniture' || isBuilder) && !!product) {
            if (onCellSelect && typeof onCellSelect === "function") onCellSelect(product);
            return openDropdown(event, product)
        }

        if (product?.gridType !== 'furniture' && !isBuilder && !product) {
            return openDropdown(event, {row, column, isEmpty: true})
        }
    }

    function onMouseEnterHandler() {
        if (hasClick && !!product && typeof onMouseEnterCell === "function") {
            return onMouseEnterCell(product)
        }
    }

    const borderStyle = `2px dashed ${product?.sectorColor}`

    return (
        <Fragment>
            {(sectorBorder?.[0] || sectorBorder?.[1] || sectorBorder?.[2] || sectorBorder?.[3]) && product?.sectorColor &&
                <style>
                    {`#cell-${product?._id} .sector-border {
                        ${sectorBorder?.[0] ? `border-top: ${borderStyle} !important;` : ""}
                        ${sectorBorder?.[1] ? `border-right: ${borderStyle} !important;` : ""}
                        ${sectorBorder?.[2] ? `border-bottom: ${borderStyle} !important;` : ""}
                        ${sectorBorder?.[3] ? `border-left: ${borderStyle} !important;` : ""}
                    `}
                </style>
            }
            <div
                id={`cell-${product?._id}`}
                ref={drop}
                className={cellClasses}
                style={{
                    [isOver && "backgroundColor"]: "rgba(255, 255, 255, 0.5)",
                    width: size?.size,
                    height: size?.size,
                    minHeight: size?.size
                }}
                onMouseUp={hasClick && !dragging ? ((event) => cellClickHandler(product, event)) : undefined}
                onMouseEnter={hasClick ? onMouseEnterHandler : undefined}
            >

                <span className='sector-border'></span>

                {product &&
                    <MapBuilderProduct
                        product={{ ...product }}
                        placed
                        connections={connections}
                        size={size}
                    />
                }

                {product?.reservations?.length > 0 && !product?.isSlot && !product?.concurrency?.quantity &&
                    <MapBuilderCellInfo product={product}/>
                }

                {product?.slots?.quantity && product?.isSlot && !isBuilder && size?.name === "L" &&
                    <MapBuilderCellInfoSlots product={product} size={size} showFuture={showFuture}/>
                }

                {product?.concurrency?.quantity && !product?.isSlot && !isBuilder && size?.name === "L" &&
                    <MapBuilderCellInfoSlots product={product} size={size} showFuture={showFuture}/>
                }

                {product?.reservations?.length > 0 && !product?.lowLight &&
                    <div className="print-cell-data">

                        {product?.reservations?.map((res) => {
                            let additionsText;
                            let customerName = getCustomersAndHotelsString(res, { lastName: 1 });
                            const additionsList = res?.products?.filter(item => ['deckchair', 'cabin'].includes(item.type));

                            if (additionsList?.length > 0) {
                                additionsList?.map(add => {
                                    const quantity = add.quantity || 1;
                                    const name = add.name.substring(0, 2) || 'L';

                                    additionsText = additionsText?.length > 0 ? `${additionsText}, ${quantity}${name}` : `${quantity}${name}`
                                })
                            }

                            return (
                                <div className="print-cell-reservation" key={res.reservation}>

                                    {["morning", "afternoon"].includes(res.durationType) && <p className="print-cell-data-text"><strong className="duration-type">{`${res.durationType === 'morning' ? 'MATTINA' : 'POMERIGGIO'}`}</strong></p>}

                                    {customerName && <p className="print-cell-data-text">{customerName}</p>}

                                    {additionsText && <p className="print-cell-data-text">{additionsText}</p>}
                                </div>
                            )
                        })}
                    </div>
                }
            </div>
        </Fragment>
    )
}