
export const mapSizes = [{
    name: 'S',
    size: 35,
}, {
    name: 'M',
    size: 50,
}, {
    name: 'L',
    size: 80,
}];