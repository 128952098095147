import { createContext, useContext } from 'react';

export function createColumns(options) {
    const { columns, rows } = options;

    return Array.apply(null, Array(columns)).map((column, index) => {
        return {
            index,
            cells: Array.apply(null, Array(rows)).map((row, rIndex) => {
                return {
                    column: index,
                    row: rIndex
                }
            })
        }
    });
}

export const MapContext = createContext({})

export function useMap() {
    return useContext(MapContext);
}
