export function trackEvent({ action, props }) {

    if (!action) {
        console.warn(
            "Analytics",
            "Action mancante"
        );
        return;
    }

    if (window.gtag && typeof window.gtag === "function") {
        if(props) {
            return window.gtag("event", action, props);
        }
        return window.gtag("event", action);
    }
}
export function setTrackConfig(props) {

    if (!props) {
        console.warn("Analytics", "Props mancanti");
        return;
    }

    if (window.gtag && typeof window.gtag === "function") {
        return window.gtag("set", props);
    }
}

export function createProduct({ name, _id, type, activity, price, list, position } = {}) {
    return {
        id: _id,
        name,
        brand: activity,
        category: type,
        price,
        list_name: list,
        list_position: position,
        quantity: 1,
    }
}