/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import classNames from 'classnames';
import { formatDate } from '@beacharound/beacharound-ui-helpers';

import { Icon } from "@beacharound/beacharound-ui";

import './style.scss';

function ChipState({
    status,
    isCheckin,
    isCheckout,
    isScheduleAfter,
    isScheduleBefore,
    isOnlineBeacharound,
    isFree,
    isPublicBeach,
    isSeasonal,
    isEmpty,
    durationType,
    hotels
}) {

    let stateIcon;
    let stateColor;
    let stateText;

    if (["PAID","OVERPAID"].includes(status)) {
        stateColor = 'green';
        stateText = "pagata";
    }

    if (["ACCESSED"].includes(status) && isPublicBeach) {
        stateColor = 'green';
        stateText = "accesso eseguito";
    }

    if (isOnlineBeacharound) {
        stateColor = 'main';
        stateText = "pagata online";
    }

    if (["PARTIALPAID"].includes(status)) {
        stateColor = 'yellow';
        stateText = "saldo mancante";
    }

    if (["ACCEPTED"].includes(status)) {
        stateColor = 'red';
        stateText = !isPublicBeach ? "da pagare" : "accesso non effettuato";
    }

    if (isFree) {
        stateColor = 'grey';
        stateText = "gratuita";
    }

    if (isEmpty) {
        stateColor = 'white';
    }

    if (isScheduleBefore) {
        stateIcon = "arrow-double-hr";
        if(!isScheduleAfter && !isCheckin) {
            stateText = `${stateText ? `${stateText} <br/> ` : ""}spostamento giorno prima`;
        }
    }

    if (isScheduleAfter && isScheduleBefore) {
        stateIcon = "arrow-double-broken-h";
        stateText = `${stateText ? `${stateText} <br/> ` : ""}spostamento prima e dopo`;
    }

    if (isCheckin && !isPublicBeach && !isCheckout) {
        stateIcon = "arrival";
        stateText = `${stateText ? `${stateText} <br/> ` : ""}in arrivo`;
    }

    if (isCheckout && !isPublicBeach && !isCheckin) {
        stateIcon = 'departure'
        stateText = `${stateText ? `${stateText} <br/> ` : ""}in partenza`;
    }

    if (isSeasonal) {
        stateIcon = 'calendar'
        stateText = `${stateText ? `${stateText} <br/> ` : ""}stagionale`;
    }

    if (isCheckin && isCheckout) {
        stateIcon = 'time'
        stateText = `${stateText ? `${stateText} <br/> ` : ""}giornaliero`;
    }

    if (hotels?.length > 0) {
        stateIcon = 'hotel'
        stateText = `${stateText ? `${stateText} <br/> ` : ""}${hotels?.[0]?.name || 'prenotazione hotel'}`;
    }

    if (status === 'OVERPAID') {
        stateIcon = 'alert'
        stateText = "rimborso da effettuare";
    }

    if(!stateColor) {
        return <Fragment></Fragment>
    }

    const ChipStateClasses = classNames({
        "chip-state": true,
        [`chip-state-${stateColor}`]: true,
        "chip-state-half": durationType && durationType !== "daily",
        [`chip-state-${durationType}`]: durationType && durationType !== "daily",
        "chip-state-blink": (status === 'OVERPAID') || (status === 'ACCEPTED' && isCheckout)
    })

    return (
        <div className={ChipStateClasses}>
            {stateIcon && <i className={`icon icon-${stateIcon}`}></i> }
            {stateText && (
                <span className="chip-state-tooltip-container">
                    <p className="chip-state-tooltip-text" dangerouslySetInnerHTML={{ __html: stateText}}></p>
                </span>
            )}
        </div>
    )
}

export default function MapChip({ active, inactive, text, reservations, isSlot, slots, isConcurrency, concurrency, size, isBuilder, future, showFuture, rating, info }) {

    const ChipClasses = classNames({
        "chip-map-container": true,
        "chip-container-active": active,
        "chip-container-inactive": inactive,
        "chip-container-multi": reservations?.some(({durationType}) => durationType && durationType !== "daily"),
        "chip-container-date": !!future?.date && !!showFuture,
        "chip-container-info": !!info,
        "chip-container-rating": !!rating,
        "chip-container-slots": isSlot,
        "chip-container-concurrency": isConcurrency,
    });

    const ChipStatesContainerClasses = classNames({
        "chip-states-container": true,
        "chip-states-container-multi-end": reservations?.length === 1 && reservations?.some(({durationType}) => durationType === "afternoon")
    });

    let sortedReservations = reservations;

    if(reservations?.length > 1) {
        sortedReservations = reservations?.sort((a) => {
            if(a.durationType === "morning") {
                return -1;
            }
            return 1;
        })
    }

    const missingType = reservations?.length === 1 && reservations?.some(({durationType}) => !durationType || durationType !== "daily") && (reservations?.some(({durationType}) => durationType === "afternoon") ? "morning" : "afternoon");
    let realText = text;
    if(slots?.quantity && !isBuilder && size?.name === "L") {
        realText = `${realText} ${slots?.available}/${slots.quantity}`
    }
    if(concurrency?.quantity && !isBuilder && size?.name === "L") {
        realText = `${realText} ${concurrency?.available < 0 ? 0 : concurrency?.available}/${concurrency.quantity}`
    }

    let futureDate;
    if(future?.date) {
        futureDate = formatDate(new Date(future?.date), 'dd MMM');
    }

    const starsEmpty = 4 - rating;
    const starsFull = rating;

    return (

        <div className={ChipClasses} title={futureDate ? `Prossima occupazione il ${futureDate}` : "Libero in futuro"}>
            {!!futureDate && !!showFuture && (
                <p className="chip-date">{futureDate}</p>
            )}
            {!!info && (
                <p className="chip-info">
                    {info}
                </p>
            )}
            {!!rating && (
                <span className="chip-rating">
                    {Array.from({ length: starsFull }, (_, i) => <Icon key={i.toString()} icon="star-full" />)}
                    {Array.from({ length: starsEmpty }, (_, i) => <Icon key={i.toString()} icon="star" />)}
                </span>
            )}
            <p className="chip-number">{realText}</p>
            {reservations?.length > 0 && !isSlot && !isConcurrency &&
                <div className={ChipStatesContainerClasses}>
                    {missingType === "morning" && <ChipState durationType={missingType} isEmpty />}
                    {sortedReservations?.map(reservation => <ChipState key={reservation?.reservation} {...reservation} />)}
                    {missingType === "afternoon" && <ChipState durationType={missingType} isEmpty />}
                </div>
            }
        </div>
    );
}