/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';

import MapBuilderCellInfoReservationItem from '../MapBuilderCellInfoReservationItem';
import MapBuilderCellInfoHeader from '../MapBuilderCellInfoHeader';

import './style.scss';

// CONTENITORE DELLA LISTA PRENOTAZIONI
function MapBuilderCellInfoReservations({ reservations, product, virtual }) {
    return (
        <div className='map-cell-info-reservations'>
            {reservations?.sort((res) => res.durationType === "morning" ? -1 : 1)?.map((res, index) => {
                return (
                    <MapBuilderCellInfoReservationItem
                        key={index}
                        reservation={res}
                        product={product}
                        virtual={virtual}
                    />
                )
            })}
        </div>
    )
}

// BODY DELLA SCHEDA INFO
function MapBuilderCellInfoBody({ product, virtual }) {
    return (        
        <div className="map-cell-info-box-content">
            <MapBuilderCellInfoReservations
                reservations={product?.reservations}
                product={product}
                virtual={virtual}
            />
        </div>
    )
}

// SCHEDA INFO
export const MapBuilderCellInfo = ({ product, virtual }) => {

    const isConfirmed = product?.reservations?.some(el => el.entry);

    const Classes = classNames({
        "map-cell-info-box": true,
        "map-cell-info-box-virtual": virtual,
        "map-cell-info-box-confirmed": isConfirmed,
        // "map-cell-info-box-checkin": hasCheckin,
        // "map-cell-info-box-checkout": hasCheckout
    })

    return (
        <div className={Classes}>

            <MapBuilderCellInfoHeader product={product} virtual={virtual} />

            <MapBuilderCellInfoBody product={product} virtual={virtual} />

        </div>  
    )
}
