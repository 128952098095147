export function trackEvent({ action, props }) {
    if (!action) {
        console.warn("Analytics", "Action mancante");
        return;
    }

    if (window.fbq && typeof window.fbq === "function") {
        if (props) {
            return window.fbq("track", action, props);
        }
        return window.fbq("track", action);
    }
}
export function setTrackConfig(props) {
    if (!props || !process.env.REACT_APP_FP_ID) {
        console.warn("Analytics", "Props mancanti");
        return;
    }

    if (window.fbq && typeof window.fbq === "function") {
        return window.fbq("init", process.env.REACT_APP_FP_ID, props);
    }
}

export function createProduct({ value, name, _id, type } = {}) {
    return {
        content_ids: [_id],
        content_type: "product",
        content_name: name,
        contents: JSON.stringify([{ id: _id, quantity: 1 }]),
        content_category: type,
        currency: "EUR",
        value: value || 0,
    }
}