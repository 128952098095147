export const daysOfTheWeekOptions = [
    {
        value: "monday",
        label: "Lunedì",
        number: 1,
    },
    {
        value: "tuesday",
        label: "Martedì",
        number: 2,
    },
    {
        value: "wednesday",
        label: "Mercoledì",
        number: 3,
    },
    {
        value: "thursday",
        label: "Giovedì",
        number: 4,
    },
    {
        value: "friday",
        label: "Venerdì",
        number: 5,
    },
    {
        value: "saturday",
        label: "Sabato",
        number: 6,
    },
    {
        value: "sunday",
        label: "Domenica",
        number: 7,
    },
];