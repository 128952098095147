import { daysOfTheWeekOptions } from "../options/daysOfTheWeek";

export function getDayOfTheWeekName(value) {
    const dayOfTheWeek = daysOfTheWeekOptions.find(
        (day) => day.value === value
    );
    
    return dayOfTheWeek?.label || "";
}

export function getDayOfTheWeekNumber(value) {
    const dayOfTheWeek = daysOfTheWeekOptions.find(
        (day) => day.value === value
    );
    
    return dayOfTheWeek?.number || "";
}