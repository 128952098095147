import React from 'react';

export default function MapBuilderCellInfoHeader({ product }) {

    const hasSchedule = product?.reservations?.some(el => el.isScheduleBefore && !el.isCheckin);
    const hasHotel = product?.reservations?.some(el => el.hotels?.length > 0);

    function getHotelNames() {
        let hotelNames;

        for (let i = 0; i < product?.reservations.length; i++) {
            const res = product?.reservations[i];
            hotelNames = hotelNames ? `${hotelNames} - ${res.hotels?.[0]?.name}}` : res.hotels?.[0]?.name
        }

        return hotelNames
    }

    function getHotels() {
        let hotels = []

        for (let i = 0; i < product?.reservations.length; i++) {
            const res = product?.reservations[i];
            const hotel = res.hotels?.[0]?.name?.toUpperCase()?.replace('HOTEL','')?.replace(' ','')

            if (hotel) {
                hotels = [ ...hotels, hotel ]
            }
        }

        return hotels
    }

    let scheduleText;
    if(hasSchedule) {
        const reservationsSchedulesBefore = product?.reservations?.filter(el => el.isScheduleBefore && !el.isCheckin);
        if(product?.reservations?.length === 1) {
            scheduleText = `Questa prenotazione è stata spostata rispetto al giorno prima`;
        } else if(reservationsSchedulesBefore?.length === product?.reservations?.length) {
            scheduleText = `Tutte queste prenotazioni sono state spostate rispetto al giorno prima`;
        } else {
            scheduleText = `Una di queste prenotazioni è stata spostata rispetto al giorno prima`;
        }
    }

    return (
        <div className="map-cell-info-box-header">
            <div className="map-cell-info-box-title-left">
                {hasHotel &&
                    <div className="map-cell-info-hotel" title={getHotelNames()}>
                        {getHotels()?.map((hotel, index) => {

                            return (
                                <div className="map-cell-info-hotel-item" key={index}>
                                    <div className="map-cell-info-hotel-content">
                                        {hotel}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                }
            </div>

            <div className="map-cell-info-title map-cell-text bold" title={product.number}>{product.number}</div>

            <div className="map-cell-info-box-title-right">
                {hasSchedule &&
                    <i className={`map-cell-info-sched icon icon-arrow-double-hr`} title={scheduleText}></i>
                }
            </div>
        </div>
    )
}