/* eslint-disable react/prop-types */

import React, { Fragment } from "react";
import classNames from 'classnames';
import { formatDate } from '@beacharound/beacharound-ui-helpers';

export const MapBuilderCellInfoSlots = ({ product, size, showFuture }) => {

    if (size?.name !== "L") {
        return <Fragment />;
    }

    let availability = product?.slots?.available ?? product?.concurrency?.available;
    if (availability < 0) {
        availability = 0;
    }
    const quantity = product?.slots?.quantity ?? product?.concurrency?.quantity;
    const number = product.number ?? product.customNumber ?? product.index;

    let text = `${availability}/${quantity} libere`;
    if (availability <= 0) {
        text = `${quantity} occupate`;
    }

    const TextClasses = classNames({
        "info-mode-cell-text": true,
        "info-mode-cell-text-border-black": availability < quantity,
    });

    let futureDate;
    if (product?.future?.date) {
        futureDate = formatDate(new Date(product?.future?.date), 'dd MMM');
    }

    return (
        <div className="info-mode-cell-data">
            <div className="info-mode-cell-text-data">
                {!!futureDate && !!showFuture && (
                    <p className="info-mode-cell-text-data-future">{futureDate}</p>
                )}
                <p className="info-mode-cell-text bold">{number}</p>
                <p className={TextClasses}>
                    {text}
                </p>
            </div>
        </div>
    );
}