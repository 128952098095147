export function getProductImage({color, category, type, placed, connections} = {}) {

    const { REACT_APP_IMAGES_PATH_ROOT } = process.env;

    let imagePath = category;

    if (color) { imagePath += `-${color}` }

    if (type === "walkway" && placed && connections) {
        imagePath += '-'
        connections.map(hasConnection => hasConnection ? imagePath += '1' : imagePath += 0)
    }

    const prodImage = `${REACT_APP_IMAGES_PATH_ROOT}/products/${imagePath}.png`

    return prodImage;
}

export function getProductsImage(products) {
    return products.map(product => {
        const image = getProductImage(product);
        return {
            ...product,
            image
        }
    })
}