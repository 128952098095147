/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import DatePicker from "react-datepicker";
import classNames from 'classnames';
import { format, addDays, isSameDay, isBefore } from 'date-fns';
import { useMap } from '../MapContext';

import { formatDate } from '@beacharound/beacharound-ui-helpers';
import { ChipsContainer, Chip, DateInput, MainForm, MapButton, ToggleInput, Icon } from '@beacharound/beacharound-ui';

import './style.scss';
import { mapSizes } from '../../lib/mapHelpers';

// eslint-disable-next-line react/display-name
const MonthDateInput = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} className="month-date-input" onClick={onClick}>{value}</button>
));

// eslint-disable-next-line react/display-name
const MobileDateInput = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} className="mobile-date-input" onClick={onClick}>{value}</button>
));

function renderDayContents(day, date) {
    return (
        <Fragment>
            <span className="date-picker-day-name">{formatDate(date, 'E')}</span>
            <span className="date-picker-day-number">{day}</span>
        </Fragment>
    );
}

export default function MapHeader({
    canInactive,
    datesRange,
    datesToCheck,
    filterDate,
    hideCalendar,
    hideGroups,
    isMultiSelect,
    isPublicBeach,
    onChangeDate,
    onChangeRange,
    onCloseAvailability,
    openDates,
    rangeMaxDate,
    rangeMinDate,
    resizable,
    schedule,
    selectedDates,
    selectedDay,
    selectedDays,
    selectedDurationType,
    selectedScheduleDates,
    setIsMultiSelect,
    setIsMultiSelectInfo,
    setSelectedCell,
    switchable,
    availabilitiesMode,
    switchAvailabilitiesMode
}) {

    const {
        showFuture,
        setShowFutureHandler,
        infoMode,
        setInfoModeHandler,
        cellSize,
        changeCellSize,
    } = useMap();

    // ZOOM MAPPA
    function changeCellSizeHandler(type) {
        const currentIndex = mapSizes.findIndex(el => el.name === cellSize?.name);

        if (type === 'in' && mapSizes[currentIndex + 1]) {
            return changeCellSize(mapSizes[currentIndex + 1])
        }
        if (type === 'out' && mapSizes[currentIndex - 1]) {
            return changeCellSize(mapSizes[currentIndex - 1])
        }

        return changeCellSize(cellSize);
    }

    function setIsMultiSelectHandler(value) {
        setSelectedCell();
        return setIsMultiSelect(value);
    }

    function closeAvailability(event) {
        event.preventDefault();
        if (!!onChangeRange && typeof onChangeRange === "function") {
            onChangeRange({});
        }
        if (!!setSelectedCell && typeof setSelectedCell === "function") {
            setSelectedCell();
        }
        if (!!setIsMultiSelect && typeof setIsMultiSelect === "function") {
            setIsMultiSelect(false);
        }
        if (!!onCloseAvailability && typeof onCloseAvailability === "function") {
            onCloseAvailability();
        }
    }

    const MapHeaderTopClasses = classNames({
        "map-header-top": true,
        "map-header-top-multiselect": !!datesRange
    });

    const MapHeaderActionsClasses = classNames({
        "map-header-actions": true,
        "map-header-actions-multiselect": !!datesRange
    });

    return (
        <div className="map-header-container">
            {!hideCalendar &&
                <div className='map-header-mobile-dates'>
                    {!schedule && !isPublicBeach &&
                        <DatePicker
                            selected={selectedDay}
                            disabledKeyboardNavigation
                            onChange={onChangeDate}
                            format={"dd/MM/yy"}
                            dateFormat={"dd/MM/yy"}
                            locale="it"
                            customInput={<MobileDateInput />}
                        />
                    }
                </div>
            }
            <div className={MapHeaderTopClasses}>
                <div className="map-header-dates-range">
                    <MainForm>
                        {!!datesRange &&
                            <button
                                className="map-header-dates-close"
                                onClick={closeAvailability}
                            >
                                <Icon icon="xmark" fa />
                            </button>
                        }
                        <DateInput
                            name="checkin"
                            dateFormat="dd MMM yyyy"
                            placeholder="Da"
                            minDate={rangeMinDate && new Date(rangeMinDate)}
                            maxDate={rangeMaxDate && new Date(rangeMaxDate)}
                            defaultValue={selectedDates?.checkin}
                            onChangeInput={(name, value) => onChangeRange({ ...(selectedDates || {}), [name]: value })}
                            excludeFormDefaultValue
                        />
                        <DateInput
                            name="checkout"
                            dateFormat="dd MMM yyyy"
                            placeholder="Fino a"
                            minDate={selectedDates?.checkin}
                            maxDate={rangeMaxDate && new Date(rangeMaxDate)}
                            defaultValue={selectedDates?.checkout}
                            onChangeInput={(name, value) => onChangeRange({ ...(selectedDates || {}), [name]: value })}
                            excludeFormDefaultValue
                        />
                    </MainForm>
                </div>

                {!!datesRange && !hideGroups &&
                    <Fragment>                      
                        <ToggleInput
                            flexEnd
                            elevated
                            small
                            trueLabel="Punteggi"
                            falseLabel="Punteggi"
                            changeValue={() => {
                                setSelectedCell()
                                switchAvailabilitiesMode();
                            }}
                            value={availabilitiesMode}
                        />
                        <div style={{ width: "8px" }}></div>
                        <ToggleInput
                            flexEnd
                            elevated
                            small
                            trueLabel="Gruppo"
                            falseLabel="Gruppo"
                            changeValue={() => {
                                setSelectedCell()
                                setIsMultiSelectHandler(!isMultiSelect);
                            }}
                            value={isMultiSelect}
                        />
                    </Fragment>
                }

                {/* <div className="map-header-dates">
                    {!schedule && !isPublicBeach &&
                        <MainForm>
                            <MainFormContent flex>
                                <DateInput
                                    name="checkin"
                                    label="Ricerca disponibilità dal"
                                    dateFormat={"dd MMMM yy"}
                                    inline
                                    defaultValue={selectedDates?.checkin}
                                    onChangeInput={(name, value) => onChangeRange({...(selectedDates || {}), [name]: value})}
                                />
                                <DateInput
                                    name="checkout"
                                    label="al"
                                    dateFormat={"dd MMMM yy"}
                                    minDate={selectedDates?.checkin}
                                    defaultValue={selectedDates?.checkout}
                                    onChangeInput={(name, value) => onChangeRange({...(selectedDates || {}), [name]: value})}
                                    inline
                                />
                            </MainFormContent>
                        </MainForm>
                    }
                </div> */}
            </div>

            {/* {!isPublicBeach && !datesRange && */}
            {!isPublicBeach && !hideCalendar &&
                <div className="map-calendar-container">
                    <div className="map-calendar-month-container">
                        {/* {!schedule && !isPublicBeach && !datesRange && */}
                        {!schedule && !isPublicBeach &&
                            <Fragment>
                                <button className='month-date-selector' onClick={() => onChangeDate(selectedDay, undefined, "left")}>
                                    <Icon icon="chevron-left" fa />
                                </button>
                                <DatePicker
                                    selected={selectedDay}
                                    disabledKeyboardNavigation
                                    onChange={onChangeDate}
                                    showMonthYearPicker
                                    format={"MMM yy"}
                                    dateFormat={"MMM yy"}
                                    locale="it"
                                    customInput={<MonthDateInput />}
                                />
                                <button className='month-date-selector' onClick={() => onChangeDate(selectedDay, undefined, "right")}>
                                    <Icon icon="chevron-right" fa />
                                </button>
                            </Fragment>
                        }
                    </div>
                    <div className="map-calendar-days-container">
                        <div
                            className="map-calendar-today"
                            onClick={() => onChangeDate(format(new Date(), 'yyyy-MM-dd'))}
                        >Oggi</div>

                        {!schedule ?
                            <div className='map-calendar-days'>
                                <DatePicker
                                    disabledKeyboardNavigation
                                    selected={selectedDay}
                                    onChange={(date) => onChangeDate(format(date, 'yyyy-MM-dd'))}
                                    inline
                                    filterDate={filterDate}
                                    locale="it"
                                    renderDayContents={renderDayContents}
                                />
                            </div>
                            :
                            <ChipsContainer>
                                {schedule?.map((item, arrayIndex) => {
                                    let color;
                                    let index = item.index;
                                    const selectedProduct = selectedScheduleDates?.find(({ date: dateToCheck }) => isSameDay(new Date(dateToCheck), new Date(item.date)));
                                    const isActive = selectedDays?.some((date) => isSameDay(new Date(date), new Date(item.date)))
                                    const isInactive = canInactive && isBefore(new Date(item.date), new Date(format(new Date(), 'yyyy-MM-dd')));

                                    if (datesToCheck?.some((dateToCheck) => isSameDay(new Date(dateToCheck), new Date(item.date)))) {
                                        color = "yellow";
                                    }

                                    if (selectedProduct) {
                                        color = "green";
                                        index = selectedProduct.index;
                                    }

                                    return (
                                        <Chip
                                            key={arrayIndex}
                                            active={isActive}
                                            inactive={isInactive}
                                            text={`${formatDate(item.date, 'EEE')} ${formatDate(new Date(item.date), "dd/MM")}`}
                                            subtext={`${index}`}
                                            action={() => !isInactive && onChangeDate(format(new Date(item.date), "yyyy-MM-dd"))}
                                            color={!isInactive && color}
                                        />
                                    )
                                })}
                            </ChipsContainer>
                        }
                    </div>
                </div>
            }

            <div className={MapHeaderActionsClasses}>
                {isMultiSelect &&
                    <MapButton icon="menu" action={() => { setSelectedCell(); setIsMultiSelectInfo(true); }} />
                }
                {switchable && ['L', 'M'].includes(cellSize?.name) &&
                    <MapButton icon={showFuture ? "calendar-xmark" : "calendar-check"} fa action={() => setShowFutureHandler(!showFuture)} />
                }
                {switchable && ['L', 'M'].includes(cellSize?.name) &&
                    <MapButton icon={infoMode ? "not-visible" : "visible"} action={() => setInfoModeHandler(!infoMode)} />
                }
                {resizable &&
                    <div className='map-header-zoom'>
                        <div className="map-header-zoom-button" onClick={() => changeCellSizeHandler('out')}>
                            <Icon icon="magnifying-glass-minus" fa />
                        </div>
                        <div className="map-header-zoom-button" onClick={() => changeCellSizeHandler('in')}>
                            <Icon icon="magnifying-glass-plus" fa />
                        </div>
                    </div>
                }
            </div>

            {isPublicBeach && !!openDates &&
                <ChipsContainer>
                    {Array.apply(null, Array(openDates)).map((item, arrayIndex) => {
                        const date = addDays(new Date(), arrayIndex);
                        return (
                            <Fragment key={arrayIndex}>
                                <Chip
                                    active={isSameDay(new Date(selectedDay), date) && selectedDurationType === 'morning'}
                                    text={`${formatDate(new Date(date), "iii dd MMM")} - Mattina`}
                                    action={() => onChangeDate(format(new Date(date), "yyyy-MM-dd"), 'morning')}
                                />
                                <Chip
                                    active={isSameDay(new Date(selectedDay), date) && selectedDurationType === 'afternoon'}
                                    text={`${formatDate(new Date(date), "iii dd MMM")} - Pomeriggio`}
                                    action={() => onChangeDate(format(new Date(date), "yyyy-MM-dd"), 'afternoon')}
                                />
                            </Fragment>
                        )
                    })}
                </ChipsContainer>
            }
        </div>
    )
}
