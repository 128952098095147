export function getSubFormData(data, keys) {

    if (!data || typeof data !== "object") {
        return false;
    }
    
    let payload = {};

    if (!Array.isArray(keys)) {
        console.error("Nessuna chiave passata");
        return payload;
    }

    return keys.reduce((acc, key) => {
        return data[key] !== undefined ? {...acc, [key]: data[key]} : acc
    }, {});
}